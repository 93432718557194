<template>
	<NuxtLoadingIndicator color="green-500" />
	<NuxtLayout>
		<NuxtPage />
	</NuxtLayout>
	<UModals />
</template>
<script setup lang="ts">
import type { PresenceUser } from "~/stores/UserStore";

const supabase = useSupabaseClient()
const supabaseUser = useSupabaseUser()
const userStore = useUserStore()

useSeoMeta({
  title: 'PCR',
  description: 'Project Controls Reporting',
  ogTitle: 'PCR',
  ogDescription: 'Project Controls Reporting',
  twitterTitle: 'PCR',
  twitterDescription: 'Project Controls Reporting',
})

useHead({
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon.png'
    }
  ]
})

const presenceData = computed(() => {
	return {
		userId: supabaseUser.value?.id || 'guest',
		name: supabaseUser.value ?  userStore.user.name : 'Guest',
		role: supabaseUser.value ? userStore.user.role : 'user'
	}
})

const { data } = supabase.auth.onAuthStateChange((event, session) => {
	setTimeout(async () => {
		await userStore.getUser()
		await updatePresence(presenceData.value.userId, presenceData.value.name, presenceData.value.role)
	}, 0)
})

const channel = supabase.channel('online_users')

channel
	.on('presence', { event: 'sync' }, () => {
		userStore.onlineUsers = Object.values(channel.presenceState())
			.flatMap(presences => presences as unknown as PresenceUser[])
			.filter(user => user.name && user.role && user.userId);
	})
	.subscribe(async (status) => {
		if (status === 'SUBSCRIBED') {
			if (supabaseUser.value)
				await updatePresence(supabaseUser.value.id, userStore.user.name, userStore.user.role)
			else
				await updatePresence('guest', 'Guest', 'user')
		}
	})

// Function to update presence
async function updatePresence(userId: string, name: string, role: string) {
	// Untrack the current presence
	await channel.untrack()
	// Track the new presence
	await channel.track({ userId, name, role })
}
</script>

<style>
.list-enter-active{
	transition: all 0.5s ease;
	transition-delay: 0.5s;
}
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
:root {
	--primary: #202020;
	/*--primary: #0f172a;*/
	--secondary: #16a34a;
}
/* Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: var(--secondary) var(--primary);
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 15px;
}

*::-webkit-scrollbar-track {
	background: var(--primary);
	border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
	background-color: var(--secondary);
	border-radius: 14px;
	border: 3px solid var(--primary);
}
</style>