import { default as _91_46_46_46slug_93Jl9wuR4KLeMeta } from "/opt/buildhome/repo/pages/cwp/[...slug].vue?macro=true";
import { default as aconexUGWXeG2Qp9Meta } from "/opt/buildhome/repo/pages/data/aconex.vue?macro=true";
import { default as pcnu9AWAvTcueMeta } from "/opt/buildhome/repo/pages/data/pcn.vue?macro=true";
import { default as qtrax3XWiKg43ogMeta } from "/opt/buildhome/repo/pages/data/qtrax.vue?macro=true";
import { default as rates9CSy1jsgM6Meta } from "/opt/buildhome/repo/pages/data/rates.vue?macro=true";
import { default as schedulerm06JtXrxEMeta } from "/opt/buildhome/repo/pages/data/schedule.vue?macro=true";
import { default as uploadRVwx3nSloxMeta } from "/opt/buildhome/repo/pages/data/upload.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93Bx6p6pIiTTMeta } from "/opt/buildhome/repo/pages/iwp/[...slug].vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_93bztIUqfPQSMeta } from "/opt/buildhome/repo/pages/packages/[...slug].vue?macro=true";
import { default as planners0E4hnt47ZzMeta } from "/opt/buildhome/repo/pages/planners.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
export default [
  {
    name: "cwp-slug",
    path: "/cwp/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/cwp/[...slug].vue")
  },
  {
    name: "data-aconex",
    path: "/data/aconex",
    component: () => import("/opt/buildhome/repo/pages/data/aconex.vue")
  },
  {
    name: "data-pcn",
    path: "/data/pcn",
    component: () => import("/opt/buildhome/repo/pages/data/pcn.vue")
  },
  {
    name: "data-qtrax",
    path: "/data/qtrax",
    component: () => import("/opt/buildhome/repo/pages/data/qtrax.vue")
  },
  {
    name: "data-rates",
    path: "/data/rates",
    component: () => import("/opt/buildhome/repo/pages/data/rates.vue")
  },
  {
    name: "data-schedule",
    path: "/data/schedule",
    component: () => import("/opt/buildhome/repo/pages/data/schedule.vue")
  },
  {
    name: "data-upload",
    path: "/data/upload",
    component: () => import("/opt/buildhome/repo/pages/data/upload.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "iwp-slug",
    path: "/iwp/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/iwp/[...slug].vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "packages-slug",
    path: "/packages/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/packages/[...slug].vue")
  },
  {
    name: "planners",
    path: "/planners",
    component: () => import("/opt/buildhome/repo/pages/planners.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/opt/buildhome/repo/pages/settings.vue")
  }
]