export default defineAppConfig({
	ui: {
		primary: 'green',
		gray: 'neutral',
		// notifications: {
		// 	position: 'top-0 right-0'
		// },
		table: {
			tr: {
				base: 'hover:bg-neutral-100 dark:hover:bg-neutral-800/50'
			},
			default: {
				expandButton: {
					variant: 'solid',
					color: 'primary',
				}
			}
		},
		card: {
			body: {
				base: ''
			}
		}
	}
})