import revive_payload_client_YDqlFGJzj6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_W43gZTJXHV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Hv2ETisOku from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tzmH66vnPZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_L19iIuLTIw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cvKNCjmg8T from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kU2LZIZSFj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hjNWamOhRd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_eY0gwmtj7p from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_flC2hNmGsr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YM3ttZA0Aq from "/opt/buildhome/repo/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.2_@pinia+nuxt@0.5.5_pinia@2.2.5/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import auth_redirect_7YxV930Bgu from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import slideovers_Oy2HgRxdUn from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_V3S10kvYNY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_gBhSDkKzWX from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_sjXhF7jAcQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.6.1_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_BcchP1HZdl from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_2lslMyhr5J from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_zh6MUJbxCQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.4_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import chartjs_1wC9lAM0Tp from "/opt/buildhome/repo/plugins/chartjs.ts";
export default [
  revive_payload_client_YDqlFGJzj6,
  unhead_W43gZTJXHV,
  router_Hv2ETisOku,
  supabase_client_tzmH66vnPZ,
  payload_client_L19iIuLTIw,
  navigation_repaint_client_cvKNCjmg8T,
  check_outdated_build_client_kU2LZIZSFj,
  chunk_reload_client_hjNWamOhRd,
  plugin_vue3_eY0gwmtj7p,
  components_plugin_KR1HBZs4kY,
  prefetch_client_flC2hNmGsr,
  plugin_YM3ttZA0Aq,
  auth_redirect_7YxV930Bgu,
  slideovers_Oy2HgRxdUn,
  modals_V3S10kvYNY,
  colors_gBhSDkKzWX,
  plugin_client_eNelkHx7DD,
  plugin_sjXhF7jAcQ,
  scrollbars_client_BcchP1HZdl,
  presets_2lslMyhr5J,
  variables_zh6MUJbxCQ,
  chartjs_1wC9lAM0Tp
]