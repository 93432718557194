import { type Database } from "~/types/database"

export const useUserStore = defineStore('user', () => {

    const user = reactive<User>({
        name: '',
        role: '',
        id: '',
        email: ''
    })

    const onlineUsers = ref<PresenceUser[]>([])

    async function getUser() {
        const supabaseUser = useSupabaseUser()
        if (!supabaseUser.value) return
        const supabase = useSupabaseClient<Database>()
        const { data, error } = await supabase
            .from('users')
            .select('*')
            .eq('id', supabaseUser.value.id)
            .maybeSingle()

        if (error) throw error
        if (data) {
            user.name = data.name
            user.role = data.role
            user.id = data.id
            user.email = data.email
        }
    }

    return { user, getUser, onlineUsers }
})

interface User {
    name: string
    role: string
    id: string
    email: string
}

export interface PresenceUser {
    userId: string
    name: string
    role: string
}